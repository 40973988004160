import React, { useEffect, useState } from "react";
import { Grid, Checkbox, FormControlLabel, MenuItem } from "@mui/material";
import AppButton from "../../../AppComponents/AppButton";
import {
  ApiContainer,
  ButtonContainerBottom,
  ButtonContainerTop,
  FormWrapper,
  ResponesBox,
  ResponseView,
  StyledInput,
  StyledLabel,
  VariableView,
} from "../style";
import DynamicKeyValueForm from "./KeyValueInput";
import DynamicTable from "./ContactProperties";
import TreeComponent from "./ResponseTreeView";
import { MultiSelect } from "./MultiSelectChips";
import CustomInputWithChips from "./customTextChips";
import { APILibraryNetworkService } from "../../../Store/Slices/APILibrary/apilibraryNetworkService";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { filterObject } from "./utils";
import { useLocation, useNavigate, useParams } from "react-router-dom";

interface Header {
  key: string;
  value: any;
}

interface ApiCreateFormProps {}

const ApiCreateForm: React.FC<ApiCreateFormProps> = () => {
  const [headers, setHeaders] = useState<Header[]>([]);
  const [formData, setFormData] = useState<Header[]>([]);
  const [showHeaders, setShowHeaders] = useState<boolean>(false);
  const [method, setMethod] = useState<string>("GET");
  const [usedInBot, setUsedInBot] = useState<string[]>([]);
  const [apiResponse, setApiResponse] = useState<any>();
  const [contactProperties, setContactProperties] = useState<any[]>([]);
  const [apiName, setApiName] = useState<string>("");
  const [url, setUrl] = useState<string>();
  const [urlConfig, setUrlConfig] = useState<any[]>([]);
  const [bodyParameters, setBodyParameters] = useState<string>("{}");
  const [bodyParametersConfig, setBodyParametersConfig] = useState<any[]>([]);
  const [payloadType, setPayloadType] = useState<string>("application/json");
  const [apiTested, setApiTested] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { showAlert } = useNotification();

  // useEffect(() => {
  //   if (showHeaders) {
  //     setHeaders([{ key: "", value: "" }]);
  //   }
  // }, [showHeaders]);
  const handleHeadersToggle = () => {
    if (showHeaders) {
      setHeaders([]);
    } else {
      setHeaders([{ key: "", value: "" }]);
    }
    setShowHeaders(!showHeaders);
  };
  useEffect(() => {
    if (headers.length < 1) {
      setShowHeaders(false);
    }
  }, [headers]);
  useEffect(() => {
    if (formData.length < 1) {
      setPayloadType("application/json");
    }
  }, [formData]);
  useEffect(() => {
    if (payloadType == "application/json") {
      setFormData([]);
      setBodyParameters("{}");
    } else {
      setFormData([{ key: "", value: "" }]);
      setBodyParameters("{}");
    }
  }, [payloadType]);

  useEffect(() => {
    setApiTested(false);
  }, [url, method, headers, apiName, payloadType]);
  const location = useLocation();
  const handleRunTest = async () => {
    const { data: header, dataConfig: headerConfig } =
      extractDataAndConfig(headers);
    const { data: formdata, dataConfig: formdataConfig } =
      extractDataAndConfig(formData);

    const parsedBodyParams = (() => {
      try {
        return bodyParameters && bodyParameters?.trim() !== ""
          ? JSON.parse(bodyParameters)
          : bodyParameters;
      } catch {
        return bodyParameters;
      }
    })();

    // Build the request object with conditional body configurations
    let reqObj = {
      name: apiName,
      method,
      payload_type: payloadType,
      url,
      url_config: urlConfig,
      header: header || {},
      header_config: headerConfig,
      ...(["POST", "PUT", "PATCH"].includes(method) && {
        body: payloadType === "application/json" ? parsedBodyParams : formdata,
        body_config:
          payloadType === "application/json"
            ? parsedBodyParams
              ? mapBodyParams(parsedBodyParams, bodyParametersConfig)
              : undefined
            : formdataConfig,
      }),
    };
    reqObj = filterObject(reqObj);
    // Validate the request object
    if (validateRequestObject(reqObj)) return;

    APILibraryNetworkService.RunTestAPI(reqObj)
      .then((res) => {
        setApiResponse(res?.data);
        setApiTested(true);
        showAlert("API Tested Succesfully", "success");
      })
      .catch((error) => {
        console.log(error);
        setApiResponse({});
        showAlert(error?.response?.data?.response || "API is not working! Please check it", "error");
      });
  };
  // Function to handle field edits
  const editMapField = (type: string, index: number, value: any) => {
    setContactProperties((prev) => {
      const updatedProperties = [...prev]; // Create a shallow copy of the array
      updatedProperties[index] = { ...updatedProperties[index], [type]: value }; // Update the specific index with the new value
      return updatedProperties; // Return the updated array
    });
  };

  function mapBodyParams(bodyparams: any, bodyparamsConfig: any[]): any[] {
    // Recursive function to traverse the bodyparams object/array
    function traverseParams(params: any, parentKey: string = ""): void {
      if (typeof params === "object" && params !== null) {
        // Loop through each key-value pair in the object or array
        for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const value = params[key];
            let currentKey = parentKey ? `${parentKey}.${key}` : key;

            // If the parentKey represents an array index, remove the index from the key path
            if (Array.isArray(params)) {
              currentKey = parentKey;
            }

            // If the value is a placeholder (matches ${{name}}), find the corresponding config
            if (
              typeof value === "string" &&
              value.startsWith("${{") &&
              value.endsWith("}}")
            ) {
              const name = value.slice(3, -2); // Extract name from placeholder, e.g., first_name

              // Find the matching config
              const config = bodyparamsConfig.find((c) => c.name === name);
              if (config) {
                config.key = currentKey; // Set the key in the config
              }
            }

            // If the value is another object or array, recurse into it
            if (typeof value === "object" && value !== null) {
              traverseParams(value, currentKey);
            }
          }
        }
      }
    }

    traverseParams(bodyparams); // Start traversing the bodyparams

    return bodyparamsConfig; // Return the updated config with keys mapped
  }
  function isDefaultValueValid(array: any[], field_name: string) {
    return array.some((obj) => {
      return (
        obj[field_name] === null ||
        obj[field_name] === "" ||
        obj[field_name] === undefined
      );
    });
  }
  const extractDataAndConfig = (array: any) => {
    const data: Record<string, string> = {};
    const dataConfig: any[] = [];

    array.forEach(
      (item: { key: string | number; value: string; config: any[] }) => {
        if (item?.key && item?.value) {
          // Add to headers object
          data[item?.key] = item?.value;

          // If config exists, add key to each config object and push to headersConfig array
          if (item?.config && item?.config?.length > 0) {
            item?.config?.forEach((configItem: any) => {
              dataConfig.push({
                ...configItem,
                key: item?.key, // Add the key to the config object
              });
            });
          }
        }
      }
    );

    return { data, dataConfig };
  };
  const createDataFromHeadersAndConfig = (
    header: Record<string, string>,
    headerConfig: any[]
  ) => {
    const data: Array<{ key: string; value: string; config: any[] }> = [];

    // Iterate through each key-value pair in the header object
    header &&
      Object.keys(header).forEach((key) => {
        const value = header[key];

        // Find all config items from headerConfig that match the current key
        const configForKey =
          headerConfig &&
          headerConfig
            .filter((configItem) => configItem.key === key)
            .map(({ key, ...rest }) => rest); // Remove the `key` field from the config object

        // Push the combined data into the result array
        data.push({
          key,
          value,
          config: configForKey,
        });
      });

    return data;
  };

  function validateRequestObject(reqObj: any): any | null {
    const errors: any = {};

    // Name validation (required, non-empty string)
    if (!reqObj.name || reqObj.name.trim() === "") {
      errors.name = "API Name is required.";
    }

    // Method validation (required, non-empty string)
    const validMethods = ["GET", "POST", "PUT", "DELETE"]; // Example of valid HTTP methods
    if (!reqObj.method || !validMethods.includes(reqObj.method.toUpperCase())) {
      errors.method = "Valid HTTP Method is required (GET, POST, PUT, DELETE).";
    }

    // Payload Type validation (required, non-empty string)
    if (!reqObj.payload_type || reqObj.payload_type.trim() === "") {
      errors.payload_type = "Payload type is required.";
    }

    // URL validation (required, non-empty, valid URL)
    const urlPattern =
      /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;

    if (!reqObj.url || !urlPattern.test(reqObj.url)) {
      errors.url = "Valid URL is required.";
    }
    // URL CONFIG
    if (
      reqObj?.url_config?.length > 0 &&
      isDefaultValueValid(reqObj.url_config, "default_value")
    ) {
      errors.url_config = "Default value is required for all URL configs.";
      showAlert("Default value is required for all URL configs.", "error");
    }
    // headers
    if (
      showHeaders &&
      headers?.length > 0 &&
      isDefaultValueValid(headers, "value")
    ) {
      errors.header = "Default value is required for all Headers.";
      showAlert("Default value is required for all Headers.", "error");
    }

    // headers config
    if (
      reqObj?.header_config?.length > 0 &&
      isDefaultValueValid(reqObj?.header_config, "default_value")
    ) {
      errors.header = "Default value is required for all Headers Config.";
      showAlert("Default value is required for all Headers Config.", "error");
    }

    // body
    if (reqObj?.method !== "GET") {
      if (reqObj?.body && typeof reqObj?.body !== "object") {
        errors.body = "Payload must be valid JSON.";
      }
    }
    if (
      reqObj?.method !== "GET" &&
      reqObj.payload_type == "multipart/form-data" &&
      formData?.length > 0 &&
      isDefaultValueValid(formData, "value")
    ) {
      errors.formdata = "Default value is required for Form-data.";
      showAlert("Default value is required for Form-data.", "error");
    }
    // body_config
    if (
      reqObj?.body_config?.length > 0 &&
      isDefaultValueValid(reqObj?.body_config, "default_value")
    ) {
      errors.mapping_config = "Default value is required for Payload Config.";
      showAlert(
        `Default value is required for ${
          reqObj.payload_type == "multipart/form-data"
            ? "Form-data "
            : "Payload "
        }Config.`,
        "error"
      );
    }

    // mapping_config
    if (
      reqObj.mapping_config &&
      (isDefaultValueValid(reqObj.mapping_config, "default_value") ||
        isDefaultValueValid(reqObj.mapping_config, "mapping_name"))
    ) {
      const mapping_name = isDefaultValueValid(
        reqObj.mapping_config,
        "mapping_name"
      );
      const default_value = isDefaultValueValid(
        reqObj.mapping_config,
        "default_value"
      );
      const message = `${default_value && "Default value"} ${
        (default_value || mapping_name) && "and"
      } ${
        mapping_name && "Mapping Field"
      }  is required for  Contact Properties.`;

      errors.mapping_config = message;
      showAlert(message, "error");
    }

    setErrors(errors);
    return Object.keys(errors)?.length === 0 ? false : true;
  }

  async function handleSubmitForm() {
    if (!apiTested) {
      showAlert("Please test the API before saving.", "error");
      return;
    }

    const { data: header, dataConfig: headerConfig } =
      extractDataAndConfig(headers);
    const { data: formdata, dataConfig: formdataConfig } =
      extractDataAndConfig(formData);

    // Safely parse body parameters
    const parsedBodyParams = (() => {
      try {
        return bodyParameters && bodyParameters.trim() !== ""
          ? JSON.parse(bodyParameters)
          : bodyParameters;
      } catch {
        return bodyParameters;
      }
    })();

    // Build the request object with conditional body and body config
    let reqObj = {
      name: apiName,
      method,
      payload_type: payloadType,
      url,
      url_config: urlConfig,
      header,
      header_config: headerConfig,
      mapping_config: contactProperties,
      used_in_bot: usedInBot,
      ...(["POST", "PUT", "PATCH"].includes(method) && {
        body: payloadType === "application/json" ? parsedBodyParams : formdata,
        body_config:
          payloadType === "application/json"
            ? parsedBodyParams
              ? mapBodyParams(parsedBodyParams, bodyParametersConfig)
              : undefined
            : formdataConfig,
      }),
    };
    reqObj = filterObject(reqObj);
    // Validate the request object
    if (validateRequestObject(reqObj)) return;

    // API operation (create or edit)
    try {
      if (id) {
        APILibraryNetworkService.EditApi(id, reqObj)
          .then(() => {
            showAlert("API Updated Succesfully", "success");
            navigate("/api/library");
          })
          .catch((error) => {
            console.log(error||"Something Went Wrong");
            showAlert(error?.response?.data?.response || "Something Went Wrong", "error");
          });
      } else {
        APILibraryNetworkService.CreateApi(reqObj)
          .then(() => {
            navigate("/api/library");
            showAlert("API Created Succesfully", "success");
          })
          .catch((error) => {
            console.log(error);
            showAlert(error?.response?.data?.response||"Something Went Wrong", "error");
          });
      }
    } catch (err: any) {
      console.error("Error during API operation:", err);
      showAlert(err?.message||"Something Went Wrong", "error");
    }
  }

  const handleSelectionChange = (
    nestedOption: string, // "all index" or "selected index"
    value: string, // The dynamic value from the JSON path
    isRemove: boolean, // Whether the action is to remove the value
    parentOption: string // "bot", "contact", or "both"
  ) => {
    // Modify value based on the nestedOption
    let modifiedValue = value;

    // Create a list of modified values for both nested options
    const modifiedValues: string[] = [];

    if (nestedOption === "all-index" || isRemove) {
      // Replace all numeric indices like `.1`, `.2`, etc., with `[]`
      modifiedValue = value.replace(/\.\d+/g, "[]");
      modifiedValues.push(modifiedValue);
    }

    if (nestedOption === "selected-index" || isRemove) {
      // Replace all `.1`, `.2`, etc., with `[1]`, `[2]`, etc.
      modifiedValue = value.replace(/\.(\d+)/g, "[$1]");
      modifiedValues.push(modifiedValue);
    }

    // Handle removal from both states if isRemove is true
    if (isRemove) {
      modifiedValues.forEach((val) => {
        setUsedInBot((prevSelected) =>
          prevSelected.filter((item) => item !== val)
        );
        setContactProperties((prevSelected) =>
          prevSelected.filter((item) => item.api_field_name !== val)
        );
      });
      return; // Exit the function after removing
    }

    // Handle "bot" or "both" cases
    if (parentOption === "bot" || parentOption === "both") {
      setUsedInBot((prevSelected) => {
        // Add the item if it's not already selected
        return prevSelected?.includes(modifiedValue)
          ? prevSelected
          : [...prevSelected, modifiedValue];
      });
    }

    // Handle "contact" or "both" cases
    if (parentOption === "contact" || parentOption === "both") {
      setContactProperties((prevSelected) => {
        const existingItem = prevSelected?.find(
          (item) => item.api_field_name === modifiedValue // Matching value for api_field_name
        );

        // Add the item if it doesn't exist
        if (!existingItem) {
          return [
            ...prevSelected,
            {
              api_field_name: modifiedValue,
            },
          ];
        }
        return prevSelected;
      });
    }
  };

  const handlePayloadConfig = (data: { chips: any[]; content: string }) => {
    let config = data?.chips?.map((i) => {
      return {
        name: i?.name || i[i?.length - 1]?.value,
        type: i?.type || i[0]?.value,
        default_value: i?.default_value || "",
        key: "",
      };
    });
    setBodyParametersConfig(config);
    setBodyParameters(data.content);
  };
  const handleUrlConfig = (data: { chips: any[]; content: string }) => {
    let config = data?.chips?.map((i) => {
      return {
        name: i.name || i[i?.length - 1]?.value,
        type: i.type || i[0]?.value,
        default_value: i?.default_value || "",
      };
    });
    setUrlConfig(config);
    setUrl(data?.content);
  };
  const handleBodyVariables =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setBodyParametersConfig((prevConfig) => {
        // Create a copy of the previous config
        const newConfig = [...prevConfig];

        // Update the default_value at the specified index
        newConfig[index] = {
          ...newConfig[index],
          default_value: value,
        };
        // Return the updated config
        return newConfig;
      });
    };

  const handleUrlVariables =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setUrlConfig((prevConfig) => {
        // Create a copy of the previous config
        const newConfig = [...prevConfig];

        // Update the default_value at the specified index
        newConfig[index] = {
          ...newConfig[index],
          default_value: value,
        };
        // Return the updated config
        return newConfig;
      });
    };
  const handleRemoveRow = (index: number) => {
    const updatedProperties = [...contactProperties];
    updatedProperties.splice(index, 1); // Remove the selected row
    setContactProperties(updatedProperties);
  };

  useEffect(() => {
    // Access the state passed from the previous page
    const { state } = location;

    if (state) {
      const { url, method, headers, data } = state;

      setUrl(url);
      setMethod(method);

      const headerData = headers
        ? Object.entries(headers).map(([key, value]) => ({ key, value }))
        : [];

      if (headerData?.length > 0) {
        setShowHeaders(true);
        setHeaders(headerData);
      }
      setBodyParameters((data && JSON.stringify(data)) || "{}");
    }
  }, [location]);

  useEffect(() => {
    if (id) {
      APILibraryNetworkService.GetApiDetails(id)
        .then((res: any) => {
          const {
            header,
            header_config,
            body,
            method,
            body_config,
            name,
            payload_type,
            used_in_bot,
            url_config,
            url,
            mapping_config,
          } = res?.data?.response;
          const headersData = createDataFromHeadersAndConfig(
            header,
            header_config
          );
          if (headersData?.length > 0) {
            setShowHeaders(true);
            setHeaders(headersData);
          }
          setBodyParameters((body && JSON.stringify(body)) || "{}");
          setBodyParametersConfig(body_config);
          setApiName(name);
          setMethod(method);
          setUrl(url);
          setUrlConfig(url_config);
          setUsedInBot(used_in_bot || []);
          setPayloadType(payload_type);
          setContactProperties(mapping_config || []);
        })
        .catch((error) => {
          showAlert(error?.message||"Something Went Wrong", "error");
          navigate("/api/library");
        });
    }
  }, []);

  return (
    <ApiContainer>
      <Grid container>
        {/* Left half with form */}
        <Grid
          item
          xs={12}
          md={6}
          style={{
            overflowY: "scroll",
            height: "100%",
            background: "#fff",
          }}
        >
          <FormWrapper>
            <form>
              {/* API Name */}
              <div>
                <StyledLabel htmlFor="api-name">
                  API Name<sup style={{ color: "red" }}>*</sup>
                </StyledLabel>
                <StyledInput
                  placeholder="Enter API Name"
                  id="api-name"
                  fullWidth
                  margin="normal"
                  size="small"
                  value={apiName}
                  disabled={id}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value;
                    if (/^[a-zA-Z0-9_]*$/.test(value)) {
                      setApiName(value);
                    }
                  }}
                  helperText={
                    errors?.name && (
                      <span style={{ color: "red" }}>{errors?.name}</span>
                    )
                  }
                  sx={
                    errors?.name && {
                      "& .MuiOutlinedInput-root": {
                        border: "1px solid red",
                      },
                    }
                  }
                />
              </div>

              {/* Methods Select */}
              <div>
                <StyledLabel htmlFor="methods">
                  Methods<sup style={{ color: "red" }}>*</sup>
                </StyledLabel>
                <StyledInput
                  id="methods"
                  select
                  fullWidth
                  margin="normal"
                  size="small"
                  value={method} // Bind the selected method
                  onChange={(e: {
                    target: { value: React.SetStateAction<string> };
                  }) => setMethod(e.target.value)} // Update method state on change
                  helperText={
                    errors?.method && (
                      <span style={{ color: "red" }}>{errors?.method}</span>
                    )
                  }
                  sx={
                    errors?.method && {
                      "& .MuiOutlinedInput-root": {
                        border: "1px solid red",
                      },
                    }
                  }
                >
                  <MenuItem value="GET">GET</MenuItem>
                  <MenuItem value="POST">POST</MenuItem>
                  <MenuItem value="PUT">PUT</MenuItem>
                  <MenuItem value="DELETE">DELETE</MenuItem>
                  <MenuItem value="PATCH">PATCH</MenuItem>
                </StyledInput>
              </div>
              {/* setPayloadType */}

              {["POST", "PUT", "PATCH"].includes(method) &&
                payloadType == "application/json" && (
                  <div>
                    <StyledLabel htmlFor="payloadType">
                      Payload Type<sup style={{ color: "red" }}>*</sup>
                    </StyledLabel>
                    <StyledInput
                      id="payloadType"
                      select
                      fullWidth
                      margin="normal"
                      size="small"
                      value={payloadType}
                      onChange={(e: {
                        target: { value: React.SetStateAction<string> };
                      }) => setPayloadType(e.target.value)}
                      helperText={
                        errors?.payload_type && (
                          <span style={{ color: "red" }}>
                            {errors?.payload_type}
                          </span>
                        )
                      }
                      sx={
                        errors?.payload_type && {
                          "& .MuiOutlinedInput-root": {
                            border: "1px solid red",
                          },
                        }
                      }
                    >
                      <MenuItem value="application/json">JSON</MenuItem>
                      <MenuItem value="multipart/form-data">
                        Multipart form data
                      </MenuItem>
                    </StyledInput>
                  </div>
                )}
              {/* URL/JS Function Name */}
              <div>
                <StyledLabel>
                  URL<sup style={{ color: "red" }}>*</sup>
                </StyledLabel>
                <CustomInputWithChips
                  defaultValue={url}
                  onInputChange={handleUrlConfig}
                  error={errors?.url}
                  allSelectedChips={urlConfig}
                />
              </div>
              {urlConfig?.length > 0 && (
                <>
                  <StyledLabel htmlFor="body-parameters">
                    URL config
                  </StyledLabel>
                  <VariableView>
                    {urlConfig?.map((conf, index) => (
                      <div>
                        <StyledLabel htmlFor="url-conf">
                          {conf?.name}
                        </StyledLabel>
                        <StyledInput
                          placeholder={`Enter ${conf?.name} Value`}
                          id="url-conf"
                          fullWidth
                          margin="normal"
                          size="small"
                          defaultValue={conf?.default_value}
                          onChange={handleUrlVariables(index)}
                        />
                      </div>
                    ))}
                  </VariableView>
                </>
              )}
              {/* Add Header */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showHeaders}
                    onChange={() => handleHeadersToggle()} // Update state based on checkbox
                  />
                }
                label={<StyledLabel>Add Header</StyledLabel>}
              />

              {/* Headers Input */}
              {showHeaders && (
                <div>
                  <DynamicKeyValueForm
                    inputName="Headers"
                    data={headers}
                    setData={setHeaders}
                  />
                </div>
              )}

              {/* Conditionally render payload input for POST method */}
              {["POST", "PUT", "PATCH"].includes(method) &&
                payloadType == "application/json" && (
                  <div>
                    <StyledLabel htmlFor="body-parameters">
                      Enter the payload content
                    </StyledLabel>
                    <CustomInputWithChips
                      defaultValue={bodyParameters}
                      onInputChange={handlePayloadConfig}
                      error={errors?.body}
                      allSelectedChips={bodyParametersConfig}
                    />
                  </div>
                )}
              {["POST", "PUT", "PATCH"].includes(method) &&
                bodyParametersConfig?.length > 0 && (
                  <>
                    <StyledLabel htmlFor="body-parameters">
                      Payload config
                    </StyledLabel>
                    <VariableView>
                      {bodyParametersConfig?.map((conf, index) => (
                        <div>
                          <StyledLabel htmlFor="api-name">
                            {conf?.name}
                          </StyledLabel>
                          <StyledInput
                            placeholder={`Enter ${conf?.name} Value`}
                            id="api-name"
                            fullWidth
                            margin="normal"
                            size="small"
                            defaultValue={conf?.default_value}
                            onChange={handleBodyVariables(index)}
                          />
                        </div>
                      ))}
                    </VariableView>
                  </>
                )}
              {/* Body Parameters */}
              {usedInBot?.length > 0 && (
                <div>
                  <StyledLabel htmlFor="body-parameters">
                    Body Parameters
                  </StyledLabel>
                  <MultiSelect
                    options={[]}
                    selectedValues={usedInBot}
                    setSelectedValues={setUsedInBot}
                  />
                </div>
              )}
              {/* Form Data */}
              {["POST", "PUT", "PATCH"].includes(method) &&
                payloadType == "multipart/form-data" && (
                  <div>
                    {/* <StyledLabel>Form-Data</StyledLabel>   */}
                    <DynamicKeyValueForm
                      inputName="Form-Data"
                      data={formData}
                      setData={setFormData}
                    />
                  </div>
                )}
              {/* Contact properties */}
              {contactProperties?.length > 0 ? (
                <div>
                  <StyledLabel
                    style={{ margin: "10px 0px" }}
                    htmlFor="contact-properties"
                  >
                    Contact Properties
                  </StyledLabel>
                  <DynamicTable
                    contactProperties={contactProperties}
                    edit={true} // Set to true to enable editing mode
                    editMapField={editMapField}
                    removeRow={handleRemoveRow}
                  />
                </div>
              ) : (
                ""
              )}
            </form>
          </FormWrapper>
        </Grid>

        <Grid item xs={12} md={6}>
          <ResponesBox>
            <ButtonContainerTop>
              {/* <AppButton
                buttonStyle={{
                  marginRight: "5px",
                  background: "#6150e1",
                  borderColor: "#6150e1",
                  borderRadius: "15px",
                }}
              > */}
              <StyledLabel>Response</StyledLabel>

              {/* </AppButton> */}
              <AppButton
                buttonStyle={{
                  marginRight: "5px",
                  padding:"10px"
                }}
                variant="primary"
                onClick={handleRunTest}
              >
                Run Test
              </AppButton>
            </ButtonContainerTop>
            <ResponseView>
              {apiResponse ? (
                <TreeComponent
                  selectedOptions={usedInBot}
                  contactProperties={contactProperties}
                  handleSelectionChange={handleSelectionChange}
                  jsonData={apiResponse}
                />
              ) : (
                ""
              )}
            </ResponseView>
            <ButtonContainerBottom>
              <AppButton
                buttonStyle={{
                  marginRight: "5px",
                  padding:"10px"
                }}
                variant="outline"
                onClick={()=>navigate("/api/library")}
              >
                Cancel
              </AppButton>
              <AppButton
                buttonStyle={{
                  marginRight: "5px",
                  padding:"10px"
                }}
                onClick={handleSubmitForm}
              >
                {id ? "Update" : "Save"}
              </AppButton>
            </ButtonContainerBottom>
          </ResponesBox>
        </Grid>
      </Grid>
    </ApiContainer>
  );
};

export default ApiCreateForm;

// vendors
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import cloneDeep from "lodash.clonedeep";
import Stack from "@mui/material/Stack";

// components
import UIButton from "../../../Components/Button";
import Input from "../../../Components/Input";
import Icon from "../../../Components/Icon";
import { getProcessedLength } from "../../../Utils/editor";


const EMAIL_REGEX =
  /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

const SendEmail = ({
  onSaveClick,
  activeButtonData,
  onCancelClick,
  language,
  maxCharLength
}: any) => {
  const [emailValue, setEmailValue] = useState<string>("");
  const [value, setValue] = useState<string>("");
  const [isEmailValid, setEmailValidity] = useState<boolean>(false);

  const validateEmail = (email: string) => {
    return EMAIL_REGEX.test(email);
  };

  const handleSaveClick = () => {
    let updatedAction = cloneDeep(activeButtonData);
    updatedAction.type = "email";
    updatedAction.value.url = emailValue;
    updatedAction.value.text = value;
    onSaveClick && onSaveClick(updatedAction);
  };

  const handleCancelClick = () => {
    onCancelClick && onCancelClick();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(getProcessedLength(event.target.value) > maxCharLength) return;
    setValue(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailValue(event.target.value);
    const isValid = validateEmail(event.target.value);
    setEmailValidity(isValid);
  };

  useEffect(() => {
    if (activeButtonData && activeButtonData.type == "email") {
      setEmailValue(activeButtonData.value.url);
      setValue(activeButtonData.value.text);
      setEmailValidity(validateEmail(activeButtonData.value.url));
    }
  }, [activeButtonData]);

  return (
    <>
      <Box sx={{ px: "16px" }}>
        <UIButton fullWidth color="error" variant="outlined">
          Send email
        </UIButton>
      </Box>
      <Box sx={{ p: "16px" }}>
        <Box>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "18px",
              color: "#7E8392",
            }}
          >
            Button title
          </Typography>
          <Input
            placeholder="Title"
            onChange={handleChange}
            value={value}
            error={value === ""}
            {...{
              helperText: maxCharLength ? (
                <Stack direction={"row"} spacing={2}>
                  <Typography variant="body2">
                    {`Limit ${getProcessedLength(value)} characters. remaining ${maxCharLength}`}
                  </Typography>
                </Stack>
              ) : (
                ""
              ),
            }}
          />
        </Box>
        <Box sx={{ pt: "16px" }}>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "18px",
              color: "#7E8392",
            }}
          >
            Email
          </Typography>
          <Input
            placeholder="Email Address"
            onChange={handleEmailChange}
            value={emailValue}
            error={emailValue !== "" && !isEmailValid}
          />
        </Box>
      </Box>
      <Box sx={{ borderTop: "1px solid #F5F6F8", p: "16px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <UIButton variant="outlined" onClick={handleCancelClick}>
            Cancel
          </UIButton>
          <UIButton
            color="primary"
            variant="contained"
            startIcon={<Icon icon="save" color="#fff" size={13} />}
            disabled={language !== "english" || !isEmailValid || value === ""}
            onClick={handleSaveClick}
          >
            Save
          </UIButton>
        </Box>
      </Box>
    </>
  );
};
export default SendEmail;

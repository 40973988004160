import React, { useEffect, useState } from "react";
import { StyledQueryBuilderWrap } from "./style";
import { ReactComponent as GroupConditionsIcon } from "../../../../../assets/images/icons/GroupConditions.svg";
import { ReactComponent as CrossIcon } from "../../../../../assets/images/close.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/images/icons/delete.svg";
import { ReactComponent as AddIcon } from "../../../../../assets/images/plus-icon.svg";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import {
  getApiListData,
  getBotId,
  getConditionalFlowQuery,
  getConditionalFlowStage,
  getContactPropertyData,
  UpdateconditionalFlowQuery,
  useFlows,
} from "../../../Slices/main.slice";
import { useAppDispatch } from "../../../../../Store";
import NestedSelectBox from "./NestedSelectBox";
import { UISelect } from "../../../../../Components/FlowBuilder/ui-components";
import {
  getApiList,
  getContactPropertyList,
} from "../../../Slices/main.service";
import { FlowBuilderService } from "../../../../../Services/Apis/FlowBuilder";
import { getText } from "../../../Utils";
import operatorOptions from "./menuOptionsforOperators.json";
import AddNodeButton from "../../../Core/AddButton";
interface Condition {
  field: any;
  operator: string;
  value: any;
  dependentOptions?: any;
}

interface ConditionGroup {
  combinator: string;
  conditions: (Condition | ConditionGroup)[];
  action?: any;
}

const HoverDropdown: React.FC<{
  onAddCondition: () => void;
  onAddGroup: () => void;
  disabled: boolean;
}> = ({ onAddCondition, onAddGroup, disabled }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      style={{ display: "block", padding: "0px 5px" }}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
    >
      <AddNodeButton
        disabled={!Boolean(anchorEl)}
        customStyle={{ my: "0px" }}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            onAddCondition();
          }}
        >
          <AddIcon />
          &nbsp;Add Condition
        </MenuItem>
        {!disabled && (
          <MenuItem
            onClick={() => {
              handleClose();
              onAddGroup();
            }}
          >
            <AddIcon />
            &nbsp;Add Group
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

const AppQueryBuilder: React.FC = () => {
  const query = [
    ...JSON.parse(JSON.stringify(useSelector(getConditionalFlowQuery))),
  ];

  const contactProperties = useSelector(getContactPropertyData);
  const apiList = useSelector(getApiListData);
  const flowList = useSelector(useFlows);
  const botId = useSelector(getBotId);
  const [options, setOptions] = useState<any[]>([
    {
      value: "Dialog's",
      type: "dialog",
      options: [],
    },
    {
      value: "Contact Properties",
      type: "property",
      options: [],
    },
    {
      value: "Api's",
      type: "api",
      options: [],
    },
  ]);
  const [actionOptions, setActionOption] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const setQuery = (data: any[]) => {
    const deepCopiedData = JSON.parse(JSON.stringify(data));
    dispatch(UpdateconditionalFlowQuery(deepCopiedData));
  };
  const activeFlowStage = useSelector(getConditionalFlowStage);

  const addCondition = (group: ConditionGroup) => {
    const newCondition: Condition = {
      field: "",
      operator: "equals",
      value: "",
    };
    group.conditions.push(newCondition);
    setQuery([...query]);
  };

  const addGroup = (group: ConditionGroup) => {
    const newGroup: any = {
      combinator: "AND",
      conditions: [{ field: "", operator: "equals", value: "" }],
    };
    group.conditions.push(newGroup);
    setQuery([...query]);
  };

  const removeCondition = (
    parentGroup: ConditionGroup | null,
    group: ConditionGroup,
    conditionIndex: number
  ) => {
    // Remove the condition at the specified index
    group.conditions.splice(conditionIndex, 1);

    // If the group is now empty
    if (group.conditions.length === 0) {
      if (parentGroup) {
        // If it's a nested group, remove it from the parent
        parentGroup.conditions = parentGroup.conditions.filter(
          (condition) => condition !== group
        );

        // If the parent group now becomes empty, replace it with an empty condition
        if (parentGroup.conditions.length === 0) {
          parentGroup.conditions.push({
            field: "",
            operator: "equals",
            value: "",
          });
        }
      } else {
        // If it's a top-level group and now empty, remove it from the query
        setQuery(query.filter((g) => g !== group));
        return; // Exit to prevent additional updates
      }
    } else if (group.conditions.length === 1) {
      // If the group has only one condition left, check if it needs to be a single condition object
      const [remainingCondition] = group.conditions;
      if (
        typeof remainingCondition === "object" &&
        "conditions" in remainingCondition &&
        remainingCondition.conditions.length === 0
      ) {
        // Replace the last group with an empty condition if needed
        group.conditions = [{ field: "", operator: "equals", value: "" }];
      }
    }

    // Update the query with the modified group
    setQuery([...query]);
  };

  const handleCombinatorChange = (
    group: ConditionGroup,
    combinator: string
  ) => {
    group.combinator = combinator;
    setQuery([...query]);
  };
  const handleNegativeAction = (group: ConditionGroup) => {
    if (!group.action) {
      group.action = {};
    }
    group.action.negative = { nodeId: "", qid: "", stage: "" };
    setQuery([...query]);
  };
  const removeNegativeAction = (group: ConditionGroup) => {
    if (group.action && group.action.negative) {
      delete group.action.negative;

      // If there are no other properties in `group.action`, remove it entirely
      if (Object.keys(group.action).length === 0) {
        delete group.action;
      }

      setQuery([...query]);
    }
  };
  const ConditionGroupComponent: React.FC<{
    index?: number;
    group: ConditionGroup;
    parentGroup: ConditionGroup | null;
    level?: number;
  }> = ({ index, group, parentGroup, level = 1 }) => {
    if (group.conditions.length === 0) return null;

    const handleFieldChange = (condition: Condition, newField: any) => {
      if (!newField) {
        condition.field = {};
        setQuery([...query]);
        return;
      }

      const { type } = newField[0];
      let data;
      switch (type) {
        case "dialog":
          data = {
            nodeId: newField[2]?.nodeId,
            qid: newField[2]?.qid,
            stage: newField[2]?.stage,
          };

          let dependentOptions;
          if (
            ["interactive-message", "interactive-message-list"].includes(
              newField[2]?.subType
            )
          ) {
            dependentOptions =
              newField[2].response[0]?.interactive?.action?.buttons.map(
                (item: any) => ({
                  type: "button",
                  value: `${item?.value?.text}`,
                  data: item,
                })
              );
          } else if (["button"].includes(newField[2]?.subType)) {
            dependentOptions = newField[2]?.response
              ?.filter((item: any) => item.type !== "text")
              .map((item: any) => ({
                type: "button",
                value: `${item?.value?.text}`,
                data: item,
              }));
          }
          condition.dependentOptions = dependentOptions;
          break;

        case "property":
          data = {
            id: newField[1]?.id,
            label: newField[1]?.label,
            name: newField[1]?.value,
          };
          break;
        case "api":
          data = {apiname:newField[1]?.value}
          break
        default:
          data = null; // or handle unexpected types as needed
          break;
      }

      condition.field = {
        type,
        value: data,
        selected: newField[newField.length - 1].value,
      };

      setQuery([...query]);
    };

    const handleValueChange = (condition: Condition, newValue: any) => {
      if (!newValue) {
        condition.value = {};
        setQuery([...query]);
        return;
      }

      let { type } = newValue[0];
      let data;
      switch (type) {
        case "dialog":
          data = {
            nodeId: newValue[2]?.nodeId,
            qid: newValue[2]?.qid,
            stage: newValue[2]?.stage,
          };
          break;

        case "property":
          data = {
            id: newValue[1]?.id,
            label: newValue[1]?.label,
            name: newValue[1]?.value,
          };
          break;
        case "text":
          data = newValue[0].value;
          break;
        case "button":
          type = "dialog";
          data = {
            nodeId: newValue[0]?.data.value?.nodeId,
            qid: newValue[0]?.data?.value?.qid,
            stage: newValue[0]?.data?.value?.stage,
          };
          break;
        case "api":
          data = {apiname:newValue[1]?.value}
          break
                default:
          data = null;
          break;
      }
      condition.value = {
        type,
        value: data,
        selected: newValue[newValue.length - 1].value,
      };

      setQuery([...query]);
    };

    const handleOperatorChange = (condition: Condition, event: any) => {
      condition.operator = event.target.value;

      if (
        ["is-valid", "not-null", "not-applicable"].includes(event.target.value)
      ) {
        condition.value = {
          type: "text",
          value: "not-applicable",
          selected: "Not Applicable",
        };
      } else {
        if (condition.value.value == "not-applicable") {
          condition.value = {};
        }
      }
      setQuery([...query]);
    };
    const handlePositiveActionChange = (newPositiveAction: any) => {
      if (!newPositiveAction) {
        group.action.positive = {};
        setQuery([...query]);
        return;
      }
      let data = {
        nodeId: newPositiveAction[1]?.nodeId,
        qid: newPositiveAction[1]?.qid,
        stage: newPositiveAction[1]?.stage,
        value: newPositiveAction[1]?.value,
      };
      group.action.positive = data;
      setQuery([...query]);
    };
    const handleNegativeActionChange = (newNegativeAction: any) => {
      if (!newNegativeAction) {
        group.action.negative = {};
        setQuery([...query]);
        return;
      }
      let data = {
        nodeId: newNegativeAction[1]?.nodeId,
        qid: newNegativeAction[1]?.qid,
        stage: newNegativeAction[1]?.stage,
        value: newNegativeAction[1]?.value,
      };
      group.action.negative = data;
      setQuery([...query]);
    };
    return (
      <div className="conditionGroup">
        {level == 1 && (
          <div className="conditionHeader">
            <span style={{ width: "auto" }}>Condition {index}</span>

            <IconButton onClick={() => removeGroup(group)}>
              <DeleteIcon />
            </IconButton>
          </div>
        )}

        {/* {group.conditions.length > 1 && ( */}
        <div className="combinatorToggle">
          <button
            className={`combinatorButton ${
              group.conditions.length > 1
                ? group.combinator === "AND"
                  ? "active"
                  : ""
                : ""
            }`}
            onClick={() => handleCombinatorChange(group, "AND")}
            disabled={group.conditions.length <= 1}
          >
            AND
          </button>
          <button
            className={`combinatorButton ${
              group.conditions.length > 1
                ? group.combinator === "OR"
                  ? "active"
                  : ""
                : ""
            }`}
            onClick={() => handleCombinatorChange(group, "OR")}
            disabled={group.conditions.length <= 1}
          >
            OR
          </button>
          <HoverDropdown
            disabled={level === 2}
            onAddCondition={() => addCondition(group)}
            onAddGroup={() => addGroup(group)}
          />
        </div>
        {/* )} */}

        {group.conditions.map((condition, index) => (
          <>
            <div key={index} className="rule">
              {"field" in condition ? (
                <>
                  <NestedSelectBox
                    placeholder="Please Select"
                    options={options}
                    value={condition?.field?.selected || ""}
                    onChange={(e) => handleFieldChange(condition, e)}
                  ></NestedSelectBox>
                  <UISelect
                    id="responseTarget"
                    value={condition.operator}
                    onChange={(e: any) => handleOperatorChange(condition, e)}
                    options={operatorOptions}
                  />
                  <NestedSelectBox
                    placeholder="Please Select"
                    options={condition?.dependentOptions || options}
                    value={condition?.value?.selected || ""}
                    onChange={(e) => handleValueChange(condition, e)}
                    readOnly={condition?.value?.type == "text" ? false : true}
                    disabled={condition?.value?.value == "not-applicable"}
                  ></NestedSelectBox>
                  {!(index === 0 && level === 1) && (
                    <IconButton
                      color="primary"
                      onClick={() => removeCondition(parentGroup, group, index)}
                      disabled={level === 1 && index === 0}
                      sx={{
                        backgroundColor: "#F5F6F8",
                        borderRadius: 1,
                        padding: "12px",
                      }}
                    >
                      <CrossIcon />
                    </IconButton>
                  )}
                </>
              ) : (
                <ConditionGroupComponent
                  group={condition as ConditionGroup}
                  parentGroup={group}
                  level={level + 1}
                />
              )}
            </div>
            {index < group.conditions.length - 1 && (
              <div className="combinatorDisplay">
                <span>{group.combinator}</span>
              </div>
            )}
          </>
        ))}

        {/* <button className="addButton" onClick={() => addCondition(group)}>
          + Add Condition
        </button> */}
        {group?.action?.positive && (
          <div style={{ paddingTop: "10px" }} className="rule">
            <>
              <div style={{ width: "10%", display: "grid" }}>
                <span style={{ alignSelf: "center", justifySelf: "center" }}>
                  Go to
                </span>
              </div>
              <NestedSelectBox
                placeholder="Please Select"
                options={actionOptions}
                value={group?.action?.positive?.value}
                onChange={(e) => handlePositiveActionChange(e)}
              ></NestedSelectBox>
              {!(index != query.length) && !group?.action?.negative && (
                <IconButton
                  color="default"
                  onClick={() => handleNegativeAction(group)}
                  sx={{
                    backgroundColor: "#F5F6F8",
                    borderRadius: 1,
                    padding: "11px",
                    fontSize: "14px",
                  }}
                >
                  <AddIcon />
                  &nbsp;&nbsp;Else
                </IconButton>
              )}
            </>
          </div>
        )}
        {group?.action?.negative && (
          <div className="rule">
            <>
              <div style={{ width: "10%", display: "grid" }}>
                <span style={{ alignSelf: "center", justifySelf: "center" }}>
                  Else
                </span>
              </div>
              <NestedSelectBox
                placeholder="Please Select"
                options={actionOptions}
                value={group?.action?.negative?.value}
                onChange={(e) => handleNegativeActionChange(e)}
              ></NestedSelectBox>
              <IconButton
                color="default"
                sx={{
                  backgroundColor: "#F5F6F8",
                  borderRadius: 1,
                  padding: "14px",
                }}
                onClick={() => removeNegativeAction(group)}
              >
                <CrossIcon />
              </IconButton>
            </>
          </div>
        )}
      </div>
    );
  };

  const removeGroup = (group: ConditionGroup) => {
    setQuery(query.filter((g) => g !== group));
  };
  useEffect(() => {
    dispatch(getContactPropertyList());
    dispatch(getApiList());
  }, []);
  function getValidNodes(childItems: any[], parentIndex: number): any[] {
    return childItems.map(
      (
        {
          id,
          text,
          name,
          isValid,
          response,
          nodeId,
          ...rest
        }: {
          id: any;
          text: any;
          isValid: boolean;
          response: any;
          name: string;
          nodeId: string;
        },
        nodeIndex: number
      ) => {
        let messageText = getText(response)?.replace(/<[^>]+>/g, ""); // Remove HTML tags
        messageText = messageText.replace(/&nbsp;/g, ""); // Replace non-breaking spaces
        // Return the object with the value and spread the rest of the child properties
        let data = {
          ...rest,
          stage: `${parentIndex}.${nodeIndex + 1}`,
          response: response,
          nodeId: nodeId,
          value: `${parentIndex}.${nodeIndex + 1} ${messageText}`,
        };
        return data;
      }
    );
  }

  const getDialogsOfFlowforAction = async (
    flowId: string,
    parentIndex: number
  ) => {
    try {
      // Attempt to fetch the flow data
      let data = await FlowBuilderService.getFlowById(flowId.toString(), {
        appid: JSON.stringify(botId),
      });
      let dialogData = getValidNodes(data?.data?.data, parentIndex);

      let actionData = dialogData.filter(
        (item, index) => item?.stage != activeFlowStage
      );
      return actionData;
    } catch (error) {
      // Handle any errors that occur during the execution
      console.error("Error fetching dialogs of flow:", error);
      return []; // You can return a default empty array or handle the error accordingly
    }
  };

  const getDialogsOfFlowForFields = async (
    flowId: string,
    parentIndex: number
  ) => {
    try {
      // Attempt to fetch the flow data
      let data = await FlowBuilderService.getFlowById(flowId.toString(), {
        appid: JSON.stringify(botId),
      });
      let dialogData = getValidNodes(data?.data?.data, parentIndex);
      let fieldOption = dialogData.filter(
        (item) =>
          item?.input == true ||
          [
            "interactive-message",
            "interactive-message-list",
            "button",
          ].includes(item?.subType)
      );
      return fieldOption;
    } catch (error) {
      // Handle any errors that occur during the execution
      console.error("Error fetching dialogs of flow:", error);
      return []; // You can return a default empty array or handle the error accordingly
    }
  };
  useEffect(() => {
    const apiOptions = apiList.map(
      (api: { name: any; used_in_bot: string[] }) => ({
        value: api?.name,
        options: api.used_in_bot
          ? api.used_in_bot.map((item: string) => ({
              value: item,
            }))
          : [],
      })
    );

    const contactOptions = contactProperties.map((property: any) => ({
      id: property?.id,
      value: property?.name,
      label: property?.label,
    }));
    const dialogOptionsForAction = flowList.map(
      (property: any, index: number) => ({
        value: `${index + 1}. ${property?.name}`,
        ...property,
        onClick: () => getDialogsOfFlowforAction(property.qid, index + 1),
      })
    );

    const dialogOptions = flowList.map((property: any, index: number) => ({
      value: `${index + 1}. ${property?.name}`,
      ...property,
      onClick: () => getDialogsOfFlowForFields(property.qid, index + 1),
    }));

    setActionOption(dialogOptionsForAction);
    let OptionData = [
      { ...options[0], options: dialogOptions },
      { ...options[1], options: contactOptions },
      { ...options[2], options: apiOptions },
    ];
    setOptions(OptionData);
  }, [apiList, contactProperties, flowList]);

  return (
    <StyledQueryBuilderWrap className="ignore-onClickOutside">
      {query.map((group, index) => (
        <>
          <ConditionGroupComponent
            key={index}
            index={index + 1}
            group={group}
            parentGroup={null}
            level={1}
          />
        </>
      ))}
    </StyledQueryBuilderWrap>
  );
};

export default AppQueryBuilder;

import styled from "styled-components";
import { theme } from "../../Customization/Theme";
import { Box, Menu, MenuItem, Select, TextField } from "@mui/material";

export const ApiLibraryWrap = styled.div`
  background-color: ${theme.palette.default.lightGrey};
  height: 100%;

  .topmenu {
    padding: 18px 22px;
    background: ${theme.palette.default.white};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: ${theme.palette.default.black};
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 61px;
  }
  .menu-left {
    display: flex;
    align-items: center;
    gap: 2rem;
    font-weight: 500;
    color: ${theme.palette.default.black};
    font-size: 20px;
  }

  .custom-btn {
    padding: 0;
  }
  .left-menu {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .left-part {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .menu-right {
    display: flex;
    align-items: center;
  }
  .edit-conditions {
    border: 1px solid ${theme.palette.default.BorderColorGrey};
  }
  .edit_text {
    font-size: 14px;
  }
  .AllFilterLogo {
    padding-right: 5px;shop
  }
  .data-container {
    background: ${theme.palette.default.white};
    margin: 1rem;
  }
  .image {
    padding-top: 24px;
    padding-left: 3rem;
  }
  .norecord {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;
    /* padding-top: 128px; */
  }
  .data-details {
    display: flex;
    /* align-items:center; */
    justify-content: center;
    padding-top: 120px;
    gap: 72px;
  }
  .data-details p {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: ${theme.palette.default.darkGrey};
  }
  .Import-details {
    width: 640px;
  }
  .Import-details button {
    width: 148px;
  }
  .Import-details li {
    margin-bottom: 1rem;
  }
  .Import-details p {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: ${theme.palette.default.black};
  }
  .button {
    display: flex;
    align-items: center;
    gap: 1rem;
    background-color: rgb(54, 84, 227);
    color: white !important;
  }
  .button button {
    /* padding: 0; */
  }
  .norecord-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
  .norecord-data p {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: ${theme.palette.default.darkGrey};
    text-transform: capitalize;
  }
  .add-list {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    padding-top: 1rem;
    gap: 2rem;
  }
  .add-list button {
    width: 148px;
  }
  .add-list p {
    width: 1115px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: ${theme.palette.default.black};
  }
  .parent_Drawer {
    width: 700px;
  }
`;

export const ApiBoxWrapper = styled.div`
  margin: 10px;
  background-color: #ffffff;
  height: calc(-224px + 100vh);
  width: 100%;
  display: flex;
  flex-direction: column; /* Stack cards vertically */
  gap: 10px; /* Adds space between cards */
  overflow-y: auto; /* Enables vertical scrolling */
  overflow-x: scroll; /* Disables horizontal scrolling if not needed */
`;

export const StyledSelect = styled(Select)`
  width: 200px; // Set fixed width
  height: 30px;
  border: 1px solid ${theme.palette.default.BorderColorGrey}; // Use theme's primary color
  border-radius: 4px; // Optional: Add border-radius
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${theme.palette.primary.main}; // Primary color on focus
  }
  margin-right: 10px;
  .Mui-selected {
    color: ${theme.palette.primary.main}; // Primary color for selected text
  }
`;

export const Container = styled(Box)`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

// export const FormWrapper = styled(Box)`
//   padding: 20px;
//   border: 1px solid #ccc;
//   border-radius: 8px;
//   background-color: #fff;
// `;

export const StyledInput = styled(TextField)`
   &.MuiFormControl-root {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  & .MuiOutlinedInput-root {
    border: 1px solid ${theme.palette.default.BorderColorGrey};
    font-size: 14px;

    & fieldset {
      border-color: ${theme.palette.default.BorderColorGrey};
    }
    &:hover fieldset {
      border-color: ${theme.palette.default.BorderColorGrey};
    }
    &.Mui-focused fieldset {
      border-color: ${theme.palette.primary.main};
    }
  }
`;

// Styled Menu component
export const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    background-color: ${theme.palette.default.white};
    border: 1px solid ${theme.palette.default.BorderColorGrey};
  }
`;

// Styled MenuItem component
export const StyledMenuItem = styled(MenuItem)`
  color: ${theme.palette.primary.main};

  &:hover {
    background-color: ${theme.palette.primary.main};
  }
`;

export const StyledLabel = styled.label`
  display: block;
  font-size: 14px;
  padding-top: 12px;
  padding-bottom: 8px;
  color: ${theme.palette.default.black};
  font-weight: 600; /* Add this line to make the text bold */
`;

export const StyledMultiSelect = styled(Select)`
  border: 1px solid ${theme.palette.default.BorderColorGrey}; // Use theme's border color
  border-radius: 4px; // Optional: Add border-radius
  width: 100%; // Set width to 100%
  height: auto; // Allow height to fit content
  margin-right: 10px; // Right margin for spacing

  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${theme.palette.primary.main}; // Primary color on focus
  }

  // Hide dropdown menu items
  .MuiList-root {
    display: none; // Hides the dropdown menu
  }

  // Style for selected items
  .Mui-selected {
    color: ${theme.palette.primary.main}; // Primary color for selected text
  }

  // Ensure select box styles are consistent
  & .MuiSelect-select {
    padding: 10px; // Padding for better spacing
    display: flex; // Use flex for alignment
    align-items: center; // Center items vertically
  }

  // Menu item styles
  .MuiMenuItem-root {
    padding: 10px 16px; // Adjust padding for menu items
  }
`;

export const ApiContainer = styled(Box)`
  display: flex;
  padding: 20px;
  height: 87vh;
`;
export const ButtonContainerBottom = styled.div`
  padding: 20px;
  display: flex;
  justify-content: flex-end; /* Align buttons to the right */
  align-items: center;
  height: 50px; /* Fixed height for top and bottom bars */
  gap: 10px; /* Add a gap between buttons */
`;
export const FormWrapper = styled(Box)`
  padding: 20px;
  marginBottom:10px;
  background-color: #fff;
  // height: 100%;
`;

export const ResponesBox = styled(Box)`
  height: 100%;
  background-color: #fff;
`;

export const ButtonContainerTop = styled.div`
  padding: 20px;
  background: #f0eefd;
  display: flex;
  justify-content: space-between; /* One button on the left, one on the right */
  align-items: center;
  height: 50px; /* Fixed height for top and bottom bars */
`;

export const ResponseView = styled.div`
  background-color: #1f1f1f;
  height: calc(
    100% - 100px
  ); /* Adjust based on the total height of fixed elements */
  display: flex;
  overflow: scroll; /* Allows for scrolling if content overflows */
  min-height: 0; /* Resetting min-height to avoid overflow issues */
`;

export const VariableView = styled.div`
  margin: 10px 0px;
  padding: 0px 10px 10px 10px;
  border: 1px solid ${theme.palette.default.BorderColorGrey};
  border-radius: 5px;
`;

export const TextAreaInput = styled.textarea`
  &::placeholder {
    color: #a2a2a2;
    font-size: 14px;
    opacity: 0.8;
    padding-left: 6px;
    padding-top: 1px;
  }
`;

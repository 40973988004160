import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Popover,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { ReactComponent as CloseIcon } from "../../../../../assets/images/close.svg";
import { ReactComponent as SearchIcon } from "../../../../../assets/images/Searchicon.svg";
import { ReactComponent as ArrowForwardIosIcon } from "../../../../../assets/images/icons/custom-select.svg";


interface NestedSelectBoxProps {
  value: any;
  onChange: (value: any | null) => void; // Return entire option objects
  options: any[];
  readOnly?: boolean;
  placeholder?:string;
  disabled?:boolean; // Use 'any' for options type, containing all fields
}

const NestedSelectBox: React.FC<NestedSelectBoxProps> = ({
  value,
  onChange,
  options,
  placeholder,
  readOnly = true,
  disabled = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [optionsStack, setOptionsStack] = useState<any[][]>([[...options]]);
  const [selectedPath, setSelectedPath] = useState<any[]>([]); // Track selected path as full option objects
  const [searchTerm, setSearchTerm] = useState("");
  const [localValue, setLocalValue] = useState(value || "");
  const handleIconClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOptionsStack([[...options]]);
    setSelectedPath([]); // Reset the path when the menu closes
    setSearchTerm("");
  };

  const handleOptionClick = async (option: any) => {
    if (option.onClick) {
      let newOption = await option.onClick();
      setOptionsStack((prevStack) => [...prevStack, newOption]);
      setSelectedPath((prevPath) => [...prevPath, option]); // Add the full option object to the path
      setSearchTerm("");
    } else if (option.options) {
      setOptionsStack((prevStack) => [...prevStack, option.options]);
      setSelectedPath((prevPath) => [...prevPath, option]); // Add the full option object to the path
      setSearchTerm("");
    } else {
      const newSelectedPath = [...selectedPath, option]; // Add the full option object to the path
      setSelectedPath(newSelectedPath);
      onChange(newSelectedPath); // Return the selected path as an array of full option objects
      handleClose();
    }
  };

  const handleBackClick = () => {
    if (optionsStack.length > 1) {
      setOptionsStack((prevStack) => prevStack.slice(0, -1));
      setSelectedPath((prevPath) => prevPath.slice(0, -1)); // Remove last level from path
      setSearchTerm("");
    }
  };

  const clearSelection = () => {
    if(disabled)
      return

    setLocalValue("");
    onChange(null);
    setOptionsStack([[...options]]);
    setSelectedPath([]); // Clear the path
  };

  const filteredOptions = Array.isArray(optionsStack[optionsStack.length - 1])
    ? optionsStack[optionsStack.length - 1].filter((option) =>
        option.value.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  const isMenuOpen = Boolean(anchorEl);
  const isNestedLevel = optionsStack.length > 1;

  useEffect(() => {
    setOptionsStack([[...options]]);
  }, [options]);
  return (
    <Box sx={{ width: "100%" }}>
      <TextField
        value={localValue}
        onChange={(e) => setLocalValue(e.target.value)}
        onBlur={() => handleOptionClick({ type: "text", value: localValue })}
        placeholder={placeholder}
        InputProps={{
          endAdornment: (
            <IconButton
              size="small"
              onClick={localValue ? clearSelection : handleIconClick}
            >
              {localValue ? (
                <CloseIcon fontSize="small" />
              ) : (
                <ArrowForwardIosIcon fontSize="small" />
              )}
            </IconButton>
          ),
          readOnly,
        }}
        fullWidth
        size="small"
        sx={{
          backgroundColor: "#F5F6F8",
          border: "none",
          borderRadius: "4px",
          "& .MuiInputBase-root": { padding: "0px" },
        }}
        disabled={disabled}
      />

      <Popover
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 2, width: 250 }}>
          {isNestedLevel && (
            <Box display="flex" alignItems="center" mb={2}>
              <IconButton size="small" onClick={handleBackClick}>
                <ArrowBackIosNewIcon fontSize="small" />
              </IconButton>
              <TextField
                placeholder="Search..."
                variant="outlined"
                fullWidth
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <IconButton size="small">
                      <SearchIcon fontSize="small" />
                    </IconButton>
                  ),
                }}
                size="small"
                sx={{
                  ml: 1,
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  "& .MuiInputBase-root": { padding: "0px" },
                }}
              />
            </Box>
          )}

          <List>
            {filteredOptions.map((option) => (
              <ListItem key={option.value} disablePadding>
                <ListItemButton onClick={() => handleOptionClick(option)}>
                  {option.value.length > 20 ? (
                    <Tooltip title={option.value} arrow>
                      <ListItemText
                        primary={
                          option.value.length > 20
                            ? `${option.value.slice(0, 20)}...`
                            : option.value
                        }
                      />
                    </Tooltip>
                  ) : (
                    <ListItemText primary={option.value} />
                  )}
                </ListItemButton>
              </ListItem>
            ))}
            {filteredOptions.length === 0 && (
              <Typography variant="body2" sx={{ mt: 2, textAlign: "center" }}>
                No options found
              </Typography>
            )}
          </List>
        </Box>
      </Popover>
    </Box>
  );
};

export default NestedSelectBox;

// vendors
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import parsePhoneNumber from "libphonenumber-js";
import cloneDeep from "lodash.clonedeep";
import Stack from "@mui/material/Stack";

// components
import UIButton from "../../../Components/Button";
import Input from "../../../Components/Input";
import Icon from "../../../Components/Icon";
import { getProcessedLength } from "../../../Utils/editor";

const CallNumber = ({
  onSaveClick,
  activeButtonData,
  onCancelClick,
  language,
  maxCharLength,
}: any) => {
  const [value, setValue] = useState<string>("");
  const [phoneValue, setPhoneValue] = useState<string>("");
  const [isPhoneNumberValid, setPhoneNumberValidity] = useState<boolean>(false);

  const checkPhoneNumberValidity = (phoneNumber: string) => {
    const phoneNumberValidity = parsePhoneNumber(phoneNumber, "IN");
    if (phoneNumberValidity) {
      return phoneNumberValidity.isValid();
    } else {
      return false;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (getProcessedLength(value) > maxCharLength) return;
    setValue(value);
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9\b]+$/;
    const value = event.target.value;
    const isVariable = /\$\{\{.*?\}\}/.test(value);
    if (value === "" || regex.test(value) || isVariable) {
      setPhoneValue(value);
      setPhoneNumberValidity(checkPhoneNumberValidity(value));
    }
  };

  const handleSaveClick = () => {
    let updatedAction = cloneDeep(activeButtonData);
    updatedAction.type = "mobile";
    updatedAction.value.url = phoneValue;
    updatedAction.value.text = value;
    onSaveClick && onSaveClick(updatedAction);
  };

  const handleCancelClick = () => {
    onCancelClick && onCancelClick();
  };

  useEffect(() => {
    if (activeButtonData && activeButtonData.type == "mobile") {
      setPhoneValue(activeButtonData.value.url);
      setValue(activeButtonData.value.text);
      setPhoneNumberValidity(
        checkPhoneNumberValidity(activeButtonData?.value?.url || ""),
      );
    }
  }, [activeButtonData]);

  return (
    <>
      <Box sx={{ px: "16px" }}>
        <UIButton fullWidth color="success" variant="outlined">
          Call Number
        </UIButton>
      </Box>
      <Box sx={{ p: "16px" }}>
        <Box>
          <Typography variant="body2">Button title</Typography>
          <Input
            placeholder="Title"
            onChange={handleChange}
            value={value}
            error={value == ""}
            {...{
              helperText: maxCharLength ? (
                <Stack direction={"row"} spacing={2}>
                  <Typography variant="body2">
                    {`Limit ${getProcessedLength(value)} characters. remaining ${maxCharLength}`}
                  </Typography>
                </Stack>
              ) : (
                ""
              ),
            }}
          />
        </Box>
        <Box sx={{ pt: "16px" }}>
          <Typography variant="body2">Phone</Typography>
          <Input
            placeholder="Phone Number"
            onChange={handlePhoneChange}
            value={phoneValue}
            error={phoneValue !== "" && !isPhoneNumberValid}
          />
        </Box>
      </Box>
      <Box sx={{ borderTop: "1px solid #F5F6F8", p: "16px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <UIButton variant="outlined" onClick={handleCancelClick}>
            Cancel
          </UIButton>
          <UIButton
            color="primary"
            variant="contained"
            startIcon={<Icon icon="save" color="common.white" size={13} />}
            disabled={
              language !== "english" || !isPhoneNumberValid || value === ""
            }
            onClick={handleSaveClick}
          >
            Save
          </UIButton>
        </Box>
      </Box>
    </>
  );
};
export default CallNumber;

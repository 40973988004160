import React, { useState } from "react";
import { IconButton, Box, MenuItem, Typography } from "@mui/material";
import { ReactComponent as Remove } from "../../../assets/images/icons/minus.svg";
import { ReactComponent as Add } from "../../../assets/images/icons/plus.svg";

import { StyledInput, StyledLabel, VariableView } from "../style";
import CustomInputWithChips from "./customTextChips";

interface DynamicKeyValueFormProps {
  data: any[];
  setData: any;
  inputName: string;
}

const DynamicKeyValueForm: React.FC<DynamicKeyValueFormProps> = ({
  data,
  setData,
  inputName,
}) => {
  const [dataConfig, setDataConfig] = useState<any[]>([]);
  const handleAddData = () => {
    setData([...data, { key: "", value: "" }]);
  };

  const handleRemoveData = (index: number) => {
    const updatedData = data.filter((_, i) => i !== index);
    setData(updatedData);
  };

  const handleChange = (
    index: number,
    e: { target: { name: any; value: any } }
  ) => {
    const { name, value } = e.target;
    const updatedData = data.map((data, i) =>
      i === index ? { ...data, [name]: value } : data
    );
    setData(updatedData); // Call onChange with updated data
  };

  const onInputChangeData = (
    index: number,
    item: { chips: any[]; content: string }
  ) => {
    const config = item?.chips?.map((i) => {
      return {
        name: i[i.length - 1]?.value,
        type: i[0]?.value,
      };
    });
    setDataConfig(config);
    setData((prevData: any) => {
      const updatedData = [...prevData];

      // Ensure config is initialized as an array if it doesn't exist
      if (!Array.isArray(updatedData[index].config)) {
        updatedData[index].config = [];
      }

      // Spread the existing config and append the new config values
      updatedData[index] = {
        ...updatedData[index],
        config: config,
        value: item.content,
      };

      return updatedData;
    });
  };

  const handleDataVariables =
    (dataIndex: number, configIndex: number) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      // Update the specific data's config at the given configIndex
      setData((prevData: any): any[] => {
        // Create a new array to avoid mutating the previous state
        const newData = [...prevData];

        // Create a new copy of the specific data's config array
        const updatedConfig = [...newData[dataIndex].config];

        // Update the default_value for the specified config
        updatedConfig[configIndex] = {
          ...updatedConfig[configIndex],
          default_value: value,
        };

        // Update the data's config with the new updatedConfig
        newData[dataIndex] = {
          ...newData[dataIndex],
          config: updatedConfig,
        };

        // Return the updated data array
        return newData;
      });
    };

  return (
    <Box>
      <Box display="flex" >
        <Box style={{ width: "45%" }}>
          <StyledLabel>{inputName} Keys</StyledLabel>
        </Box>
        <Box style={{ width: "45%" }}>
          <StyledLabel>{inputName} Values</StyledLabel>
        </Box>
      </Box>

      {data.map((item, index) => (
        <Box key={index} display="flex" alignItems="center" mb={1}>
          <Box display="flex" sx={{ width: "90%" }}>
            <StyledInput
              placeholder="Enter Key"
              name="key"
              value={item.key}
              onChange={(e: any) => handleChange(index, e)}
              variant="outlined"
              size="small"
              style={{ marginRight: "10px", width: "50%" }}
            />
            <CustomInputWithChips
              inputStyle={{ marginRight: "10px", width: "50%" }}
              onInputChange={(e) => onInputChangeData(index, e)}
              defaultValue={item.value}
              rows={1}
              scroll={false}
              allSelectedChips={dataConfig}
              placeholder="Enter Value"
            />
          </Box>
          <Box display="flex" sx={{ width: "10%" }}>
            <IconButton onClick={() => handleRemoveData(index)}>
              <Remove />
            </IconButton>

            <IconButton onClick={handleAddData}>
              <Add />
            </IconButton>
          </Box>
        </Box>
      ))}
      {/* dataConfig */}
      {data.some((data) => data.config?.length > 0) && (
        <>
          {/* Render the label once for all data configs */}
          <StyledLabel htmlFor="body-parameters">
            {inputName} Config
          </StyledLabel>

          <VariableView>
            {data?.map(
              (data, index) =>
                data?.config?.length > 0 && (
                  <div key={`data-config-${index}`}>
                    {data?.config?.map(
                      (
                        conf: { name: any; default_value: any },
                        configIndex: number
                      ) => (
                        <div key={`config-${index}-${configIndex}`}>
                          <StyledLabel
                            htmlFor={`api-name-${index}-${configIndex}`}
                          >
                            {data.key + `:` + conf?.name}
                          </StyledLabel>
                          <StyledInput
                            placeholder={`Enter ${conf?.name} Value`}
                            id={`api-name-${index}-${configIndex}`}
                            fullWidth
                            margin="normal"
                            size="small"
                            defaultValue={conf?.default_value}
                            onChange={handleDataVariables(index, configIndex)}
                          />
                        </div>
                      )
                    )}
                  </div>
                )
            )}
          </VariableView>
        </>
      )}
    </Box>
  );
};

export default DynamicKeyValueForm;

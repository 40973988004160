// vendors
import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { v4 as uuid } from "uuid";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import isEqual from "lodash.isequal";

// components
import Icon from "../Components/Icon";
import Card from "../Components/Card";
import NodeType from "../Components/NodeType";
import Modal from "../Components/Modal";
import ActionsPopover from "./ActionsPopover";
import ActionCheckbox from "./actions/ActionCheckbox";
import Action from "./Action";
import SaveProperty from "./SaveProperty";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";

// styled
import {
  ToolbarContainerStyled,
  ToolbarActionsWrapperStyled,
} from "./NodeWrapper.styled";

// hooks
import { useAppDispatch } from "../../../Store";
import useActions from "./useActions";
import { useCurrentBot } from "../../../Store/Slices/DashboardSlices";

// slice
import {
  setActiveNode,
  getActiveNode,
  getBotId,
  useNodes,
  getUnSavedNodeId,
  setUnSavedNodeId,
  setQuickAction,
  setActiveAction,
  updateNodes,
  updateModifiedData,
  handleRightDrawer,
  UpdateconditionalFlowQuery,
  setActiveFlowStage,
} from "../Slices/main.slice";
import { deleteNode, createNode } from "../Slices/main.service";

// utils
import { getQuickAction } from "../Utils";
import AppButton from "../../../AppComponents/AppButton";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { ReactComponent as ConditionalNode } from "../../../assets/images/icons/ConditionalNode.svg";

interface Props {
  children: React.ReactElement;
  config: NodeConfig;
  stage: string;
  node: any;
  onOTPCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isLast: boolean;
  hasOTPNode: boolean;
}

const NodeWrapper = (props: Props) => {
  const {
    children,
    config,
    stage,
    node,
    onOTPCheckboxChange,
    isLast,
    hasOTPNode,
  } = props;
  const { qid, nodeId, quickActions, hasOTPVarification, property } = node;
  const { name, icon, color } = config;
  const currentBot = useSelector(useCurrentBot);
  const [showToolbar, setShowToolbar] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(true);
  const [actionError, setActionError] = useState<string>("");
  const [openModal, updatedOpenModal] = useState<boolean>(false);
  const [modalMessage, updateModalMessage] = useState<{
    type: string;
    message: string;
  }>({ type: "", message: "" });
  const [userInputQuery, updateUserInputQuery] = useState<boolean>(
    getQuickAction(quickActions, "user-query")
  );
  const [showProperty, updateSavedProperty] = useState<boolean>(false);
  const [isPropertyChecked, setIsPropertyChecked] = useState<boolean>(
    property ? true : false
  );
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const dispatch = useAppDispatch();
  const activeNodeId = useSelector(getActiveNode);
  const botId = useSelector(getBotId);
  const nodes = useSelector(useNodes);
  const unsavedNodeId = useSelector(getUnSavedNodeId);
  const { deleteChildrenNode, saveProperty } = useActions();
  const isActiveNode = activeNodeId === nodeId;
  const isUnSavedNode = unsavedNodeId === nodeId;
  const cardRef = useRef();
  const { showAlert } = useNotification();

  const handleMouseEnter = (event: React.MouseEvent<Element>) => {
    const classes = (event.target as Element).className || "";
    if (
      classes &&
      typeof classes === "string" &&
      classes?.includes("button-popover-backdrop")
    ) {
      return;
    }
    setShowToolbar(true);
  };

  const handleMouseLeave = (event: React.MouseEvent<Element>) => {
    const classes: string = (event.target as Element).className || "";
    if (
      classes &&
      typeof classes === "string" &&
      classes?.includes("button-popover-backdrop")
    ) {
      return;
    }
    setShowToolbar(false);
  };

  const handleClick = () => {
    dispatch(setActiveNode(nodeId));
    if (node?.response && node?.response.length > 0 && activeNodeId === null) {
      dispatch(updateModifiedData(node.response));
    }
  };

  const handleDuplicateClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    updateModalMessage({
      type: "duplicate",
      message: "Do you want duplicate this node?",
    });
    updatedOpenModal(true);
  };

  const handleDeleteClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    updateModalMessage({
      type: "delete",
      message: "Do you want delete this node?",
    });
    updatedOpenModal(true);
  };

  const handleCloseClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (isUnSavedNode) {
      updateModalMessage({
        type: "delete",
        message: "Are you sure this node will get discarded?",
      });
      updatedOpenModal(true);
      return;
    }
    dispatch(setActiveNode(null));
  };

  const handleSaveClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    if (modalMessage.type === "delete") {
      const matchedNode = nodes.find((node: any) => node.nodeId === nodeId);
      const isInBetween = matchedNode.displayOrder + 1 < nodes.length;

      if (matchedNode.parentId) {
        deleteChildrenNode(
          qid,
          nodeId,
          isInBetween,
          matchedNode.parentId,
          matchedNode.id
        );
      } else {
        let nodeIds: string[] = [];
        matchedNode?.childrens?.map((id: string) => {
          nodes.map((node: any) => {
            if (node.id === id) nodeIds.push(node.nodeId);
          });
        });
        dispatch(
          deleteNode({
            botId,
            qid,
            nodeId,
            isInBetween,
            childrens: matchedNode?.childrens ? nodeIds : [],
          })
        ).then((res: any) => {
          if (res.payload && res.payload?.status === 200) {
            showAlert("Node Deleted Successfully!", "success");
          } else {
            showAlert(
              res.payload?.data ?? "Node Not Deleted Successfully!",
              "error"
            );
          }
        });
      }

      updatedOpenModal(false);
      dispatch(setActiveNode(null));
      dispatch(setUnSavedNodeId(null));
      return;
    }

    if (modalMessage.type === "duplicate") {
      const matchedNode = nodes.find((node: any) => node.nodeId === nodeId);
      const path = Number(matchedNode.displayOrder);
      const duplicatedNode = {
        ...matchedNode,
        id: uuid(),
        displayOrder: path + 1,
        stage: String(parseFloat(stage) + 0.1),
        isInBetween: matchedNode.displayOrder + 1 < nodes.length,
      };
      delete duplicatedNode.nodeId;
      delete duplicatedNode.childrens;
      dispatch(createNode({ botId, node: duplicatedNode }));
      updatedOpenModal(false);
      return;
    }
  };

  const handleCancelClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    updatedOpenModal(false);
  };

  const handleModalClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    updatedOpenModal(false);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    updateUserInputQuery(value);
    dispatch(setQuickAction([{ enabled: value, type: "user-query" }]));
  };

  const handleSavePropertyChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.checked;
    setIsPropertyChecked(value);
    updateSavedProperty(value);
  };

  const handleSavePropertyPopoverClose = () => {
    updateSavedProperty(false);
  };

  const handleActionClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    cardRef.current && setOpenPopover(cardRef.current);
    setActionError("");
  };

  const handlePopoverClose = () => {
    setOpenPopover(null);
  };

  const handleActionSelect = (action: any) => {
    const isDuplicateActionAllowed = node?.actions?.find(
      (tAction: any) => tAction.type === action.type && action.shouldBeAddedOnce
    );

    if (!isDuplicateActionAllowed) {
      const clone = Object.assign({}, action);
      delete clone.icon;
      delete clone.color;
      delete clone.displayComponent;
      const actionId = uuid();
      clone.id = actionId;
      clone.actionId = actionId;
      dispatch(setActiveAction(clone));
      dispatch(updateNodes({ nodeId, actions: [clone] }));
      setOpenPopover(null);
      setShowPreview(false);
      setActionError("");
    } else {
      setActionError("Selected Action cannot be repeat.");
    }
  };
  const handleSaveProperty = (event: any, property: any) => {
    event.stopPropagation();
    if (node?.property && isEqual(node?.property, property)) return;
    saveProperty({ property }, () => {
      updateSavedProperty(false);
    });
  };
  const handleConditionDrawer = (event: any,data: any[] = [],stage?:string) => {
    event.stopPropagation();
    dispatch(setActiveNode(nodeId));
    dispatch(
      handleRightDrawer({ componentType: "conditional-flow", isOpen: true })
    );
    dispatch(UpdateconditionalFlowQuery(data));
    dispatch(setActiveFlowStage(stage));
    
  };
  return (
    <>
      <Card
        className="ignore-onClickOutside node-card"
        focused={isActiveNode}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        {!node?.parentId && showToolbar && (
          <ToolbarContainerStyled
            isActiveNode={isActiveNode}
            isUnSavedNode={isUnSavedNode}
          >
            <ToolbarActionsWrapperStyled>
              {isActiveNode && (
                <Tooltip title="Close" placement="right-start">
                  <IconButton onClick={handleCloseClick}>
                    <Icon icon="close" color="default.darkGrey" size={14} />
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip title="Action" placement="right-start">
                <IconButton onClick={handleActionClick}>
                  <BoltOutlinedIcon />
                </IconButton>
              </Tooltip>
              {(unsavedNodeId === null || unsavedNodeId !== nodeId) && (
                <Tooltip title="Duplicate" placement="right-start">
                  <IconButton onClick={handleDuplicateClick}>
                    <Icon icon="duplicate" color="default.darkGrey" size={16} />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Conditional Node" placement="right-start">
                
                <IconButton
                  onClick={(e) =>
                    handleConditionDrawer(e, node?.conditional_flow,stage)
                  }
                >
                  <ConditionalNode />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete" placement="right-start">
                <IconButton onClick={handleDeleteClick}>
                  <Icon icon="delete" color="default.darkGrey" size={16} />
                </IconButton>
              </Tooltip>
            </ToolbarActionsWrapperStyled>
          </ToolbarContainerStyled>
        )}

        <Tooltip
          title={isActiveNode ? "" : "Click To Edit"}
          followCursor={true}
          placement="top"
        >
          <Box>
            <NodeType
              text={name}
              color={color}
              icon={icon}
              secondaryText={stage}
            />
            <Divider />
            <Box ref={cardRef}>{children}</Box>
            {(!hasOTPNode || hasOTPVarification) &&
              config.type === "phone-number" && (
                <Box
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Divider />
                  <ActionCheckbox
                    label="Enable OTP Varification"
                    checked={hasOTPVarification}
                    onCheckBoxChange={onOTPCheckboxChange}
                  />
                </Box>
              )}
            {isActiveNode && (
              <>
                <Divider />
                <Box
                  id={`save-property-${activeNodeId}`}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <ActionCheckbox
                    label="Save the response as property"
                    tooltipLabel="This is tooltip."
                    checked={isPropertyChecked}
                    onCheckBoxChange={handleSavePropertyChange}
                  />
                  {isPropertyChecked && (
                    <AppButton
                      variant="text"
                      onClick={() => updateSavedProperty(true)}
                    >
                      {" "}
                      <Icon icon="pencil" size={14} color="#7E8392" />
                    </AppButton>
                  )}
                </Box>
              </>
            )}
            {isActiveNode &&
              currentBot?.channel === "Web Bot" &&
              config.type === "text" && (
                <>
                  <Divider />
                  <Box
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <ActionCheckbox
                      label="Enable user input query"
                      tooltipLabel="This is user input query tooltip."
                      checked={userInputQuery}
                      onCheckBoxChange={handleCheckboxChange}
                    />
                  </Box>
                </>
              )}
          </Box>
        </Tooltip>
      </Card>

      {node?.actions && (
        <Action
          actions={node.actions}
          id={node.nodeId}
          editableState={showPreview}
        />
      )}
      {/* kdchanges */}
      {node?.conditional_flow?.length > 0 && (
        <Box
          sx={{
            margin: "16px 0px",
            width: "100%",
            height: "fit-content",
            cursor: "pointer",
          }}
        >
          <Card
            secondary
            onClick={(e) => handleConditionDrawer(e,node?.conditional_flow,stage)}
          >
            <Box sx={{ p: "12px 16px", cursor: "pointer" }}>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <ConditionalNode />
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 500 }}
                  color="primary.light"
                >
                  Connected conditional Flows
                </Typography>
              </Box>
            </Box>
          </Card>
        </Box>
      )}
      <Modal
        open={openModal}
        type={"default"}
        size={"xs"}
        onClose={handleModalClose}
        primaryButtonConfig={{
          label: "Yes",
          onClick: handleSaveClick,
        }}
        secondaryButtonConfig={{
          label: "Cancel",
          onClick: handleCancelClick,
        }}
        header={{ title: "Confirm" }}
      >
        <Typography variant="body2" sx={{ pb: "4px" }}>
          {modalMessage.message}
        </Typography>
      </Modal>
      <ActionsPopover
        actions={node?.actions ? node.actions : []}
        open={openPopover}
        onClose={handlePopoverClose}
        onActionSelect={handleActionSelect}
        isLast={isLast}
        error={actionError}
      />
      <SaveProperty
        property={node?.property}
        anchorEl={document.getElementById(`save-property-${activeNodeId}`)}
        open={showProperty}
        onClose={handleSavePropertyPopoverClose}
        onSave={handleSaveProperty}
      />
    </>
  );
};
export default NodeWrapper;

// vendors
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// services
import {
  fetchFlow,
  fetchFlows,
  createFlow,
  deleteFlow,
  updateFlow,
  deleteNode,
  createNode,
  getContactPropertyList,
  getApiList,
} from "./main.service";

const INITIAL_STATE: any = {
  error: "",
  loading: false,
  language: "english",
  flows: [],
  nodes: [],
  activeFlow: null,
  activeNode: null,
  botId: null,
  unSavedNodeId: null,
  modifiedNodeData: [],
  quickActions: [],
  buttonsList: [],
  dateConfiguration: {},
  timeConfiguration: {},
  carousel: [],
  inputs: {},
  activeAction: {},
  rightDrawer: { isOpen: false, componentType: "" },
  conditionalFlowQuery: [],
  updatedconditionalFlowQuery:false,
  conditionalFlowStage:"",
  apiList: [],
  contactPropertyList: [],
};

const flowBuilder = createSlice({
  name: "core",
  initialState: INITIAL_STATE,
  reducers: {
    setActiveFlow: (state, action) => {
      const activeFlow = state?.flows?.filter(
        (flow: Flow) => flow.qid === action.payload
      );
      return {
        ...state,
        activeFlow: activeFlow.length === 1 ? activeFlow[0] : null,
      };
    },
    setActiveNode: (state, action) => {
      return {
        ...state,
        activeNode: action.payload,
      };
    },
    setBotId: (state, action) => {
      return {
        ...state,
        botId: action.payload,
      };
    },
    setUnSavedNodeId: (state, action) => {
      return {
        ...state,
        unSavedNodeId: action.payload,
      };
    },
    updateModifiedData: (state, action) => {
      return {
        ...state,
        modifiedNodeData: action.payload,
      };
    },
    setQuickAction: (state, action) => {
      return {
        ...state,
        quickActions: action.payload,
      };
    },
    setActiveAction: (state, action) => {
      return {
        ...state,
        activeAction: action.payload,
      };
    },
    setButtonsList: (state, action) => {
      return {
        ...state,
        buttonsList: action.payload,
      };
    },
    setDateConfiguration: (state, action) => {
      return {
        ...state,
        dateConfiguration: action.payload,
      };
    },
    setTimeConfiguration: (state, action) => {
      return {
        ...state,
        timeConfiguration: action.payload,
      };
    },
    setCarouselData: (state, action) => {
      return {
        ...state,
        carousel: action.payload,
      };
    },
    setInputValues: (state, action) => {
      return {
        ...state,
        inputValues: action.payload,
      };
    },
    updateNodes: (state, action) => {
      const tempNode = [...state.nodes];
      const index = state.nodes.findIndex(
        (node: any) => node.nodeId == action.payload.nodeId
      );
      if (tempNode[index].actions) {
        tempNode[index].actions = [
          ...tempNode[index].actions,
          ...action.payload.actions,
        ];
      } else {
        tempNode[index].actions = [...action.payload.actions];
      }

      state.nodes = tempNode;
    },
    handleRightDrawer: (
      state,
      action: PayloadAction<{
        isOpen?: boolean;
        componentType?: string;
        title?: string;
      }>
    ) => {
      if (action.payload.isOpen !== undefined) {
        state.rightDrawer.isOpen = action.payload.isOpen; // Set to specified value
      } else {
        state.rightDrawer.isOpen = !state.rightDrawer.isOpen; // Toggle visibility
      }

      if (action.payload.componentType !== undefined) {
        state.rightDrawer.componentType = action.payload.componentType; // Set the component type
      }
    },
    UpdateconditionalFlowQuery: (state, action) => {
      return {
        ...state,
        conditionalFlowQuery: action.payload,
      };
    },
    saveConditionalFlowQuery: (state, action) => {
      return {
        ...state,
        updatedconditionalFlowQuery:action.payload,
      };
    },
    setActiveFlowStage: (state, action) => {
      return {
        ...state,
        conditionalFlowStage: action.payload,
      };
    },
    
    AddNewconditionalFlowQuery: (state, action) => {
      return {
        ...state,
        conditionalFlowQuery: [...state.conditionalFlowQuery, action.payload],
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFlows.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchFlows.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        if (typeof action.payload !== "string") {
          console.log("==>action.payload",action.payload)
          state.flows = action.payload;
        }
        state.loading = false;
      }
    );
    builder.addCase(fetchFlows.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
      state.loading = false;
    });
    builder.addCase(fetchFlow.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchFlow.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.nodes = typeof action.payload === "object" ? action.payload : [];
        const unsavedNode = action.payload.filter(
          (node: any) => node.isUnsaved
        );
        state.activeNode =
          unsavedNode.length === 1 ? unsavedNode[0].nodeId : null;
        state.unSavedNodeId =
          unsavedNode.length === 1 ? unsavedNode[0].nodeId : null;
        state.loading = false;
      }
    );
    builder.addCase(fetchFlow.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
      state.loading = false;
    });
    builder.addCase(createFlow.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createFlow.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createFlow.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
      state.loading = false;
    });
    builder.addCase(deleteFlow.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteFlow.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteFlow.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
      state.loading = false;
    });
    builder.addCase(updateFlow.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateFlow.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateFlow.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
      state.loading = false;
    });
    builder.addCase(deleteNode.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      deleteNode.fulfilled,
      (state, action: PayloadAction<any>) => {
        if (action.payload.data.status === 201) {
          state.error = action.payload.data.data || "Something went wrong";
        }
        state.loading = false;
      }
    );
    builder.addCase(deleteNode.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
      state.loading = false;
    });
    builder.addCase(createNode.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createNode.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createNode.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
      state.loading = false;
    });
    // getContactPropertyList
    builder.addCase(getContactPropertyList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getContactPropertyList.fulfilled, (state, action) => {
      state.contactPropertyList = action?.payload?.data?.data || [];
    });
    builder.addCase(getContactPropertyList.rejected, (state, action) => {
      state.error = action?.error?.message || "Something went wrong";
      state.loading = false;
    });
    // getApiList
    builder.addCase(getApiList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getApiList.fulfilled, (state, action) => {
      state.apiList = action?.payload?.data?.response || [];
    });
    builder.addCase(getApiList.rejected, (state, action) => {
      state.error = action?.error?.message || "Something went wrong";
      state.loading = false;
    });
  },
});

export default flowBuilder.reducer;

export const {
  setActiveFlow,
  setActiveNode,
  setBotId,
  setUnSavedNodeId,
  updateModifiedData,
  setQuickAction,
  setButtonsList,
  setDateConfiguration,
  setTimeConfiguration,
  setCarouselData,
  setInputValues,
  setActiveAction,
  updateNodes,
  handleRightDrawer,
  AddNewconditionalFlowQuery,
  UpdateconditionalFlowQuery,
  saveConditionalFlowQuery,
  setActiveFlowStage
} = flowBuilder.actions;

export const useFlows = (state: any) => {
  return state.flowBuilder.main.flows as any;
};

export const useNodes = (state: any) => {
  return state.flowBuilder.main.nodes as any;
};

export const getActiveFlow = (state: any) => {
  return state.flowBuilder.main.activeFlow as any;
};

export const getActiveNode = (state: any) => {
  return state.flowBuilder.main.activeNode as any;
};

export const getLanguage = (state: any) => {
  return state.flowBuilder.main.language as any;
};

export const getBotId = (state: any) => {
  return state.flowBuilder.main.botId as any;
};

export const getUnSavedNodeId = (state: any) => {
  return state.flowBuilder.main.unSavedNodeId as any;
};

export const getModifiedNodeData = (state: any) => {
  return state.flowBuilder.main.modifiedNodeData as any;
};

export const getQuickActions = (state: any) => {
  return state.flowBuilder.main.quickActions as any;
};

export const getActiveAction = (state: any) => {
  return state.flowBuilder.main.activeAction as any;
};

export const getButtonsList = (state: any) => {
  return state.flowBuilder.main.buttonsList as any;
};

export const getDateConfiguration = (state: any) => {
  return state.flowBuilder.main.dateConfiguration as any;
};

export const getTimeConfiguration = (state: any) => {
  return state.flowBuilder.main.timeConfiguration as any;
};

export const getCarouselData = (state: any) => {
  return state.flowBuilder.main.carousel as any;
};

export const getInputValues = (state: any) => {
  return state.flowBuilder.main.inputValues as any;
};

export const getRightDrawerStatus = (state: any) => {
  return state.flowBuilder.main.rightDrawer as any;
};

export const getConditionalFlowQuery = (state: any) => {
  return state.flowBuilder.main.conditionalFlowQuery as any;
};
export const getConditionalFlowStage = (state: any) => {
  return state.flowBuilder.main.conditionalFlowStage as any;
};
export const getSavedConditionalFlowQuery = (state: any) => {
  return state.flowBuilder.main.updatedconditionalFlowQuery as any;
};

export const getContactPropertyData = (state: any) => {
  return state.flowBuilder.main.contactPropertyList as any;
};

export const getApiListData = (state: any) => {
  return state.flowBuilder.main.apiList as any;
};

/**
* Utility function to execute callback for eack key->value pair.
*/
export function forEach(obj: any, callback: any) {
    if (obj) {
      for (const key in obj) { // eslint-disable-line no-restricted-syntax
        if ({}.hasOwnProperty.call(obj, key)) {
          callback(key, obj[key]);
        }
      }
    }
  }
  
  export function hasProperty(obj: any, property: any) {
    let result = false;
    if (obj) {
      for (const key in obj) { // eslint-disable-line no-restricted-syntax
        if ({}.hasOwnProperty.call(obj, key) && property === key) {
          result = true;
          break;
        }
      }
    }
    return result;
  }
  
  /**
  * The function returns true if the string passed to it has no content.
  */
  export function isEmptyString(str: any) {
    return !str || !str.trim()
  }
  
  /**
  * The function will return true for simple javascript object,
  * which is not any other built in type like Array.
  */
  export function isMap(obj: any) {
    return Object.prototype.toString.call(obj) === '[object Object]';
  }
  
  /**
  * The function will return filter out props fron and return new props.
  */
  export function filter(obj: any, keys: any) {
    const filteredKeys: any = Object.keys(obj).filter(key => keys.indexOf(key) < 0);
    const filteredObject: any = {};
    if (filteredKeys && filteredKeys.length > 0) {
      filteredKeys.forEach((key: any) => {
        filteredObject[key] = obj[key];
      });
    }
    return filteredObject;
  }
  
  export function stopPropagation(event: any) {
    event.stopPropagation();
  }
  

  export function transformApiAndContactOptions(
    apiList?: any[],
    contactProperties?: any[]
  ){
    // Transform API list data into options
    const apiOptions = apiList?.map(
      (api: { name: any; used_in_bot: string[] }) => ({
        value: api?.name,
        label: api?.name,
        options: api?.used_in_bot
          ? api.used_in_bot.map((item: string) => ({
              value: `${"${{"}${api?.name}.${item}${"}}"}`,
              label: item,
            }))
          : [],
      })
    );

    // Transform Contact Properties data into options
    const contactOptions = contactProperties?.map((property: any) => ({
      id: property?.id,
      value: `${"${{"}${property?.name}${"}}"}`,
      label: property?.label,
    }));

    return {
      apiOptions,
      contactOptions,
    };
  };
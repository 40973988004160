import React, { useEffect, useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { ApiLibraryNetworkActions } from "../../../Store/Slices/APILibrary/apilibrary.actions";
import { useAppDispatch, useAppSelector } from "../../../Store";
import {
  getContactPropertiesOptions,
  getApiListOptions,
} from "../../../Store/Slices/APILibrary/apilibrary.selectors";

interface NestedMenuProps {
  anchorEl: HTMLElement | null;
  handleChange: (
    selectedHierarchy: Array<{ value: string; label: string }>
  ) => void;
}

interface Option {
  value: string;
  label: string;
  sublevels?: Option[];
}

const NestedMenu: React.FC<NestedMenuProps> = ({ anchorEl, handleChange }) => {
  const dispatch = useAppDispatch();
  const apiList = useAppSelector(getApiListOptions);
  const contactList = useAppSelector(getContactPropertiesOptions);

  const [options, setOptions] = useState<Option[]>([]);
  const [selectedHierarchy, setSelectedHierarchy] = useState<Option[]>([]);

  // Define initial options
  const initialOptions: Option[] = [
    {
      value: "API_GATEWAY",
      label: "APIs",
      sublevels: apiList?.map((api) => ({
        value: api?.name,
        label: api?.name,
        sublevels: api.used_in_bot
          ? api.used_in_bot.map((item: string) => ({
              value: `${api?.name}.${item}`,
              label: `${api?.name}.${item}`,
            }))
          : [],
      })),
    },
    {
      value: "CONTACT_PROPERTIES",
      label: "Contact Properties",
      sublevels: contactList.map((property) => ({
        value: property?.name,
        label: property?.name,
      })),
    },
  ];

  // Fetch API and contact properties when component mounts
  useEffect(() => {
    dispatch(ApiLibraryNetworkActions.getApiListOption({}));
    dispatch(ApiLibraryNetworkActions.getContactProperties({}));
  }, [dispatch]);

  // Set options with fetched data
  useEffect(() => {
    const apiOptions = apiList.map((api) => ({
      value: api?.name,
      label: api?.name,
      sublevels: api.used_in_bot
        ? api.used_in_bot.map((item: string) => ({ value: `${api?.name}.${item}`, label: `${api?.name}.${item}` }))
        : [],
    }));

    const contactOptions = contactList.map((property) => ({
      value: property?.name,
      label: property?.name,
    }));

    // Set options with initial state and fetched sublevels
    setOptions([
      { ...initialOptions[0], sublevels: apiOptions },
      { ...initialOptions[1], sublevels: contactOptions },
    ]);
  }, [apiList, contactList]);

  const handleClose = () => {
    // Reset options to initial state with all sublevels
    setOptions(initialOptions);
    setSelectedHierarchy([]); // Reset selected hierarchy
    handleChange([]); // Reset on close
  };

  const handleOptionSelect = (option: Option) => {
    const newHierarchy = [
      ...selectedHierarchy,
      { value: option.value, label: option.label },
    ];
    console.log("==> option.sublevels",option.sublevels)
    if (option.sublevels && option.sublevels.length > 0) {
      // If the selected option has sublevels, update the options and selected hierarchy
      setOptions(option.sublevels);
      setSelectedHierarchy(newHierarchy);
    } else {
      // If the selected option does not have sublevels, call handleChange and close the menu
      handleChange(newHierarchy);
      handleClose();
    }
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      sx={{ minWidth: 200 }}
    >
      {options.length > 0 ? (
        options.map((option, index) => (
          <MenuItem
            sx={{ minWidth: 200 }}
            key={index}
            onClick={() => handleOptionSelect(option)}
          >
            {option.label}
          </MenuItem>
        ))
      ) : (
        <MenuItem sx={{ minWidth: 200 }} disabled>
          No Options
        </MenuItem>
      )}
    </Menu>
  );
};

export default NestedMenu;
